import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { Text, Button, Center, VStack, Image } from '@chakra-ui/react';

import Wrapper from 'shared/Wrapper';
import Head from 'shared/Head';

import status from 'assets/notFound/404.svg';
import plug from 'assets/notFound/plug.svg';
import socket from 'assets/notFound/socket.svg';

const pageDatas = [
  {
    title: 'Страница не найдена',
    text: 'Похоже, вы попали в мультивселенную. <br /> Чтобы вернуться в реальность, нажмите на кнопку и зажмурьтесь на 3 секунды',
  },
  {
    title: 'Кажется, эта страница в отпуске',
    text: 'Но не расстраивайтесь! Все самое интересное ждёт вас на главной',
  },
];

export default function PageNotFound() {
  const [pageData] = useState(() => pageDatas[Math.floor(Math.random() * pageDatas.length)]);

  return (
    <>
      <Head title="Page Not Found" description="Page Not Found" />
      <Wrapper
        as="main"
        w="full"
        maxW="unset"
        h={{
          mobile: 'calc(100dvh - 42px)',
          tablet: 'calc(100dvh - 64px)',
          laptop: 'calc(100dvh - 66px)',
          xxl: 'calc(100dvh - 102px)',
        }}
        minH={{ mobile: '560px', tablet: '450px', laptop: '500px', xxxl: '700px' }}
        pt="0"
        px={{ mobile: '23.5px', tablet: 'unset' }}
        pb="0"
      >
        <Center w="full" h="full" pos="relative" zIndex="15">
          <VStack spacing="0">
            <Image
              src={status}
              alt="404 статус ошибки"
              objectFit="cover"
              w={{ mobile: '200px', laptop: '332px', desktop: '445px' }}
              h={{ mobile: '100px', laptop: '165px', desktop: '220px' }}
            />
            <Text
              as="h1"
              textStyle="header.h3"
              textAlign="center"
              mt={{ mobile: '16px', laptop: '24px', desktop: '32px' }}
            >
              {pageData?.title}
            </Text>
            <Text
              as="p"
              textStyle="h1"
              textAlign="center"
              mt={{ mobile: '12px', laptop: '16px', desktop: '20px' }}
              maxW={{ laptop: '500px', desktop: '600px' }}
              dangerouslySetInnerHTML={{ __html: pageData?.text }}
            />
            <Button
              textStyle="button.primary"
              fontSize={{ mobile: '18px', desktop: '24px' }}
              letterSpacing={{ tablet: '-0.02em' }}
              mt={{ mobile: '24px', laptop: '40px', desktop: '60px' }}
              p={{ mobile: '20px 32px', desktop: '32px 48px' }}
              onClick={() => navigate('/')}
            >
              Вернуться на главную
            </Button>
          </VStack>
        </Center>

        <Image
          src={socket}
          alt="Изображение розетки"
          objectFit="cover"
          pos="absolute"
          top={{ mobile: '11px', tablet: '10px', laptop: '71px', desktop: '16px' }}
          left={{ mobile: '-34px', tablet: '-37px', laptop: '0' }}
          w={{ mobile: '242px', md: '290px', xxxl: '520px', xd: '660px' }}
          h={{ mobile: '114px', md: '136px', xxxl: '245px', xd: '310px' }}
        />
        <Image
          src={plug}
          alt="Изображение электрической вилки"
          objectFit="cover"
          pos="absolute"
          bottom={{ mobile: '14px', tablet: '36px', laptop: '78px', desktop: '32px' }}
          right={{ mobile: '-53px', tablet: '-57px', laptop: '0' }}
          w={{ mobile: '248px', md: '297px', xxxl: '545px', xd: '670px' }}
          h={{ mobile: '120px', md: '144px', xxxl: '265px', xd: '326px' }}
        />
      </Wrapper>
    </>
  );
}
